export const namespaces = {
	authService: "Authorization Service",
	connectionService: "Connections Service",
	integrationService: "Integrations Service",
	triggersUI: "Triggers UI",
	deploymentsService: "Deployments Service",
	buildService: "Builds Service",
	environmentsService: "Environments Service",
	manifestService: "Manifest Service",
	projectService: "Project Service",
	projectUI: "Project UI",
	projectUICode: "Project UI - Code",
	resourcesService: "Resources Service",
	sessionsHistory: "Sessions History",
	sessionsService: "Sessions Service",
	triggerService: "Trigger Service",
	variableService: "Variable Service",
	buildRuntimeEntrypoints: "Convert runtime to entrypoints",
	eventsService: "Events Service",
	ui: {
		loginPage: "Login Page",
		deleteModal: "Delete Modal",
		projectCodeEditor: "Project Code Editor",
	},
	hooks: {
		connectionForm: "Connection Form",
	},
	stores: {
		connectionCheckerStore: "Connection Checker Store",
		cache: "Cache Store",
		activitiesStore: "Activity Logs Store",
		outputStore: "Output Logs Store",
	},
};
